// libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";


// components
import Header from "../../components/header/header.component";
import ApartmentMarjana from "./apartments/marjana";
import ApartmentMalyMnich from "./apartments/malymnich";
import ApartmentUKasnych from "./apartments/kasnych";
import ApartmentKaplicka from "./apartments/kaplicka";
import Footer from "../../components/footer/footer.component";
import Translator from "../../components/translator/translator.component";

// assets
import CabinIcon from "../../assets/images/vectors/icon-cabin.svg";

function Accommodation() {
  const { t } = useTranslation();
  const [pension, setPension] = useState('');

  return (
    <>
      <Header
        title={t('header.accommodation.title')}
        highlight={t('header.accommodation.titleHighlight')}
        buttonText={t('header.accommodation.titleButtonText')}
      />
      <main>
        <article>
          <h2 className="article-heading-l2">
            {t('accommodation.title')}
          </h2>
          <div className="article-paragraph mb-sm">
            <p>
              {t('accommodation.paragraph_1')}
            </p>
            <p>
              {t('accommodation.paragraph_2')}
            </p>
          </div>
          <div className="flex-container accommodation">
            <button
              onClick={() => setPension('marjana')}
              className={`btn btn--white
                ${pension === 'marjana' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={CabinIcon} alt="icon of a house" />
                </span>
                Penzion Marjána
            </button>
            <button
              onClick={() => setPension('kasnych')}
              className={`btn btn--white
                ${pension === 'kasnych' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={CabinIcon} alt="icon of a house" />
                </span>
                Penzion U Kašných
            </button>
            <button
              onClick={() => setPension('mnich')}
              className={`btn btn--white
                ${pension === 'mnich' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={CabinIcon} alt="icon of a phone" />
                </span>
                Apts. Malý Mnich
            </button>
            <button
              onClick={() => setPension('kaplicka')}
              className={`btn btn--white
                ${pension === 'kaplicka' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={CabinIcon} alt="icon of a phone" />
                </span>
                Penzion U Kapličky
            </button>
          </div>
          {pension &&
            <>
              {pension === 'marjana' && <ApartmentMarjana />}
              {pension === 'kasnych' && <ApartmentUKasnych />}
              {pension === 'mnich' && <ApartmentMalyMnich />}
              {pension === 'kaplicka' && <ApartmentKaplicka />}
            </>
          }
        </article>
      </main>
      <Footer />
      <Translator />
    </>
  );
}

export default Accommodation;