// libraries
import { useRef } from 'react';

// styles
import "./header.styles.css";

// assets
import Village from "../../assets/images/vectors/village-wedding.svg"

function Header({ title, highlight, buttonText }) {
  const headingRef = useRef();

  function scrollToTopic() {
    window.scrollTo({
      top: headingRef.current.scrollHeight - 80,
      left: 0,
      behavior: "smooth"
    });
  }
  
  return (
    // hero header
    <header ref={headingRef}>
      <section className="hero">
        <div className="hero__left">
          <h1>{title} <span>{highlight}</span></h1>
          <div>
            <button
              onClick={scrollToTopic}
              className="btn btn--white"
              href="#"
              title={buttonText}>
                {buttonText}
            </button>
          </div>
        </div>
      </section>
      <img src={Village} alt="vector graphic of a village wedding" />
    </header>
  );
}

export default Header;