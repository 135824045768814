// libraries
import { useTranslation } from "react-i18next";

// assets
import PensionUKasnych from "../../../assets/images/pension_2.jpg";

function ApartmentUKasnych() {
  const { t } = useTranslation();

  return (
    <div className="tabcontent__container">
      <h3>Penzion U Kašných</h3>
      <div className="accommodation__flex">
        <div className="accommodation__img">
          <img src={PensionUKasnych} alt="pension u kasnych" />
        </div>
        <div className="accommodation__description">
          <p>
            {t('accommodation.pensions.u_kasnych')}
          </p>
          <h4>
            {t('accommodation.pensions.contact')}
          </h4>
          <p>
            <a
              href="https://www.penzion-kasny.cz/"
              className="btn btn--white"
              target="_blank" rel="noreferrer">
                {t('accommodation.pensions.web')}
            </a>                     
            <a
              href="https://www.booking.com/Share-uzoRQk"
              className="btn btn--white"
              target="_blank" rel="noreferrer">
                Booking.com
            </a>                     
          </p>
        </div>
      </div>      
    </div>
  );
}

export default ApartmentUKasnych;