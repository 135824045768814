// libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// components
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import Car from "./transport/car";
import Train from "./transport/train";
import RideSharing from "./transport/ridesharing";
import Translator from "../../components/translator/translator.component";

// styles
import "./venue.styles.css";

// assets
import CarIcon from "../../assets/images/vectors/icon-car.svg";
import TrainIcon from "../../assets/images/vectors/icon-train.svg";
import PhoneIcon from "../../assets/images/vectors/icon-phone.svg";
import VenueOne from  "../../assets/images/venue_1.jpg";
import VenueTwo from  "../../assets/images/venue_2.jpg";
import VenueThree from  "../../assets/images/venue_3.jpg";
import VenueFour from  "../../assets/images/venue_4.jpg";

function Venue() {
  const [transport, setTransport] = useState('');
  const { t } = useTranslation();

  return (
    <>
      <Header
        title={t('header.venue.title')}
        highlight={t('header.venue.titleHighlight')}
        buttonText={t('header.venue.titleButtonText')}
      />   
      <main>
        <article>
          <h2 className="article-heading-l2">
            {t('venue.intro.title')}
          </h2>
          <p>{t('venue.intro.intro_paragraph')}</p>
          <table>
            <tbody>
              <tr>
                <td>{t('venue.intro.table.venue')}</td>
                <td>Statek u Kapličky</td>
              </tr>
              <tr>
                <td>{t('venue.intro.table.address')}</td>
                <td>Nová Ves pod Pleší 24, 262 04</td>
              </tr>
              <tr>
                <td>{t('venue.intro.table.map')}</td>
                <td>
                <a href="https://maps.app.goo.gl/r4TPPPuQ7ZoJESqh8"
                    target="_blank"
                    rel="noreferrer">
                      {t('venue.intro.table.map_value')}
                  </a>
                </td>
              </tr>
              <tr>
                <td>{t('venue.intro.table.web')}</td>
                <td>
                  <a href="https://www.statek-ukaplicky.cz"
                    target="_blank"
                    rel="noreferrer">
                      www.statek-ukaplicky.cz
                  </a>
                </td>
              </tr>
              <tr>
                <td>GPS</td>
                <td>49.83552078001906, 14.27533864739247</td>
              </tr>
            </tbody>
          </table>
          <h2 className="article-heading-l2">
            {t('venue.gallery')}
          </h2>
          <div className="gallery__container mb-lg">
            <div className="gallery__frame">
              <img src={VenueOne} alt="first image of the venue" />
            </div>
            <div className="gallery__frame">
              <img src={VenueTwo} alt="second image of the venue" />
            </div>
            <div className="gallery__frame">
              <img src={VenueThree} alt="third image of the venue" />
            </div>
            <div className="gallery__frame">
              <img src={VenueFour} alt="fourth image of the venue" />
            </div>
          </div>
          <h2 className="article-heading-l2">
            {t('venue.directions.title')}
          </h2>
          <p>{t('venue.directions.paragraph_1')}</p>
          <div className="flex-container">
            <button
              onClick={() => setTransport('car')}
              className={`btn btn--white
                ${transport === 'car' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={CarIcon} alt="icon of a car" />
                </span>
                {t('venue.directions.car.title')}
            </button>
            <button
              onClick={() => setTransport('train')}
              className={`btn btn--white
                ${transport === 'train' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={TrainIcon} alt="icon of a train" />
                </span>
                {t('venue.directions.train.title')}
            </button>
            <button
              onClick={() => setTransport('ridesharing')}
              className={`btn btn--white
                ${transport === 'ridesharing' ? 'active' : ''}`}>
                <span className="button-icon">
                  <img src={PhoneIcon} alt="icon of a phone" />
                </span>
                {t('venue.directions.ridesharing.title')}
            </button>
          </div>
          {transport &&
            <>
              {transport === 'car' && <Car />}
              {transport === 'train' && <Train />}
              {transport === 'ridesharing' && <RideSharing />}
            </>
          }
         </article>
      </main>
      <Footer />
      <Translator />
    </>
  );
}

export default Venue;