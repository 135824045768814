// libraries
import { useState } from "react";
import i18next from "i18next";

// styles
import "./translator.styles.css";

// assets
import TranslatorIcon from "../../assets/images/vectors/icon-translation.svg";
import ArrowUpIcon from "../../assets/images/vectors/icon_arrow_up.svg";

// language selection
const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'sr',
    name: 'Српски'
  }
];

function Translator() {
  const [languageMenuIsVisible, setLanguageMenuIsVisible] = useState(false);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  function handleLanguageChange(code) {
    // change system language
    i18next.changeLanguage(code);
    // hide languages menu
    setLanguageMenuIsVisible(!languageMenuIsVisible);
  }

  return (
    <>      
      <button
        onClick={() => setLanguageMenuIsVisible(!languageMenuIsVisible)}
        className="btn--languages">
        <img src={TranslatorIcon} alt="icon of a globe" />
      </button>
      {languageMenuIsVisible && (
        <div className="languages__menu">
          {languages.map(language => {
            return (
              <button
                onClick={() => handleLanguageChange(language.code)}
                key={language.code}className="languages__lang">
                {language.name}
              </button>
            );
          })}
        </div>
      )}
      {window.innerWidth < 577 && 
      <>
        <div className="languages__mobile"></div>
        <button
          onClick={() => scrollToTop()}
          className="btn--languages">
          <img src={ArrowUpIcon} alt="icon of an upward arrow" />
        </button>
      </>}      
    </>
  );
}

export default Translator;