// libraries
import { useTranslation } from "react-i18next";

// assets
import PensionMarjana from "../../../assets/images/pension_1.jpg";

function ApartmentMarjana() {
  const { t } = useTranslation();
  
  return (
    <div className="tabcontent__container">
      <h3>Penzion Marjána</h3>
      <div className="accommodation__flex">
        <div className="accommodation__img">
          <img src={PensionMarjana} alt="pension marjana" />
        </div>
        <div className="accommodation__description">
          <p>
            {t('accommodation.pensions.marjana')}
          </p>
          <h4>
            {t('accommodation.pensions.contact')}
          </h4>
          <p>
            <a
              href="https://www.marjana.cz"
              className="btn btn--white"
              target="_blank" rel="noreferrer">
                {t('accommodation.pensions.web')}
            </a>                     
            <a
              href="https://www.booking.com/Share-ua8Yhx"
              className="btn btn--white"
              target="_blank" rel="noreferrer">
                Booking.com
            </a>                     
          </p>
        </div>
      </div>      
    </div>
  );
}

export default ApartmentMarjana;