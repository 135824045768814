// libraries
import { useTranslation } from "react-i18next";

// styles
import "./tabcontent.styles.css";

function Car() {
  const { t } = useTranslation();
  
  return (
    <div className="tabcontent__container">
      <h3>
        {t('venue.directions.car.sub_title_1')}
      </h3>
      <p>
        {t('venue.directions.car.directions_1a')}
        <span className="text-highlight yellow">Statek u Kapličky</span>
        {t('venue.directions.car.directions_1b')}
        <span className="text-highlight yellow">Nová Ves pod Pleší 24, 262 04</span>.
      </p>
      <h3>
        {t('venue.directions.car.sub_title_2')}
      </h3>
      <p>
        {t('venue.directions.car.directions_2_part_1a')}
        <span className="text-highlight green">D4</span>
        {t('venue.directions.car.directions_2_part_1b')}
        <span className="text-highlight green">Strakonice</span>.
        {t('venue.directions.car.directions_2_part_1c')}
        <span className="text-highlight green">116</span>
        {t('venue.directions.car.directions_2_part_1d')}
        <span className="text-highlight green">Nový Knín / Mníšek P.B</span>.</p>
      <p> 
        {t('venue.directions.car.directions_2_part_2a')}
        <span className="text-highlight green">116</span>
        {t('venue.directions.car.directions_2_part_2b')}
        <span className="text-highlight green">Nový Knín / Nová Ves P.P</span>.
        {t('venue.directions.car.directions_2_part_2c')}
        <span className="text-highlight green">116</span>
        {t('venue.directions.car.directions_2_part_2d')}
        <span className="text-highlight green">Nový Knín</span>.</p>
      <p>
        {t('venue.directions.car.directions_2_part_3')}
      </p>
    </div>
  );
}

export default Car;