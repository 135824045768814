// libraries
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import Header from "../../components/header/header.component";
import Card from "../../components/card/card.component";
import Footer from "../../components/footer/footer.component";
import Translator from "../../components/translator/translator.component";

// assets
import PhotoOne from "../../assets/images/photo_1.jpg";
import PhotoTwo from "../../assets/images/photo_2.jpg";
import PhotoThree from "../../assets/images/photo_3.jpg";

// styles
import "./home.styles.css";

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Header
        title={t('header.home.title')}
        highlight={t('header.home.titleHighlight')}
        buttonText={t('header.home.titleButtonText')}
      />
      <main>
        <article>
          <h2 className="article-heading-l2">{t("home.intro.savedate")}</h2>
          <div className="article-paragraph">
            <p className="address">{t("home.intro.address")}</p>
            <p>{t("home.intro.intro_part_1")}</p>
            <p>{t("home.intro.intro_part_2")}</p>
            <table>
              <tbody>
                <tr>
                  <td>{t("home.intro.venue.when")}</td>
                  <td>{t("home.intro.venue.date")}</td>
                </tr>
                <tr>
                  <td>{t("home.intro.venue.where")}</td>
                  <td>Statek u Kapličky, Nová Ves pod Pleší</td>
                </tr>
              </tbody>
            </table>
            <p>{t("home.intro.intro_part_3")}</p>
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="/venue"
              className="btn btn--secondary">
                {t("home.intro.intro_button_read_more")}
            </Link>
          </div>
          <h2 className="article-heading-l2">{t("home.story.ourstory")}</h2>
          <div className="article__grid">
            {/* article one */}
            <div className="article__grid--image">
              <img src={PhotoOne} alt="First photo of us" />
            </div>
            <div className="article__grid--description">
              <p>{t("home.story.ourstory_part_1")}</p>
            </div>                        
            {/* article one */}
            <div className="article__grid--description">
              <p>{t("home.story.ourstory_part_2")}</p>
            </div>
            <div className="article__grid--image">
              <img src={PhotoTwo} alt="Second photo of us" />
            </div>
             {/* article three */}
             <div className="article__grid--image">
              <img src={PhotoThree} alt="Third photo of us" />
            </div>
            <div className="article__grid--description">
              <p>{t("home.story.ourstory_part_3")}</p>
            </div>
          </div>
          <h2 className="article-heading-l2">{t("home.about.about_wedding")}</h2>
          <div className="card__flex mb-lg">
            <Card
              linkTitle="Venue"
              title={t("home.about.card_1.title")}
              description={t("home.about.card_1.description")}
            />
            <Card
              linkTitle="Agenda"
              title={t("home.about.card_2.title")}
              description={t("home.about.card_2.description")}
            />
            <Card
              linkTitle="Accommodation"
              title={t("home.about.card_3.title")}
              description={t("home.about.card_3.description")}
            />
          </div>
        </article>
      </main>
      <Footer />
      <Translator />
    </>
  );
}

export default Home;