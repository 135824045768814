// libraries
import { useTranslation } from "react-i18next";

// styles
import "./footer.styles.css";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      &copy; {t("footer.title")}<span>Aurélie & Vlad</span>
    </footer>
  );
}

export default Footer;