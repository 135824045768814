// libraries
import { useTranslation } from "react-i18next";

// assets
import PensionMalyMnich from "../../../assets/images/pension_3.jpg";

function ApartmentMalyMnich() {
  const { t } = useTranslation();

  return (
    <div className="tabcontent__container">
      <h3>Apartmány Malý Mnich</h3>
      <div className="accommodation__flex">
        <div className="accommodation__img">
          <img src={PensionMalyMnich} alt="apartmany maly mnich" />
        </div>
        <div className="accommodation__description">
          <p>
            {t('accommodation.pensions.maly_mnich')}
          </p>
          <h4>
            {t('accommodation.pensions.contact')}
          </h4>
          <p>
            <a
              href="https://www.malymnich.cz/en/"
              className="btn btn--white"
              target="_blank" rel="noreferrer">
                {t('accommodation.pensions.web')}
            </a>                     
            <a
              href="https://www.booking.com/Share-qpmPu8m"
              className="btn btn--white"
              target="_blank" rel="noreferrer">
                Booking.com
            </a>                     
          </p>
        </div>
      </div>      
    </div>
  );
}

export default ApartmentMalyMnich;