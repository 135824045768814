// libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
import Navbar from "./components/navbar/navbar.component";

// pages
import Home from "./pages/home/home";
import Agenda from "./pages/agenda/agenda";
import Venue from "./pages/venue/venue";
import Accommodation from "./pages/accommodation/accomodation";
import NotFound from "./pages/notfound/notfound";

// app styles
import "./App.css";

function App() {
  return (    
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/agenda" element={<Agenda />} />
        <Route path="/venue" element={<Venue />} />
        <Route path="/accommodation" element={<Accommodation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;