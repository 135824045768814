// libraries
import { useTranslation } from "react-i18next";

// styles
import "./tabcontent.styles.css";

function Train() {
  const { t } = useTranslation();

  return (
    <div className="tabcontent__container">
      <h3>
        {t('venue.directions.train.sub_title_1')}
      </h3>
      <p>
        {t('venue.directions.train.directions_1_part_1a')}
        <span className="text-highlight yellow">Praha hlavní nádraží</span>
        {t('venue.directions.train.directions_1_part_1b')}
        <a href="https://maps.app.goo.gl/MiUKXfV5pwANybqx5" target="_blank" rel="noreferrer">
          {t('venue.directions.train.directions_1_part_1c')}
        </a>.
      </p>      
      <p>
        {t('venue.directions.train.directions_1_part_2a')}
        <a href="https://www.cd.cz/en/spojeni-a-jizdenka/" target="_blank" rel="noreferrer">České dráhy</a>
        {t('venue.directions.train.directions_1_part_2b')}
        <span className="text-highlight yellow">Praha hl.n.</span>
        {t('venue.directions.train.directions_1_part_2c')}
        <span className="text-highlight yellow">Nová Ves p.Pleší</span>
        {t('venue.directions.train.directions_1_part_2d')}
      </p>
      <p>
        {t('venue.directions.train.directions_1_part_3a')}
        <span className="text-highlight yellow">
          {t('venue.directions.train.directions_1_part_3b')}
        </span>.
        {t('venue.directions.train.directions_1_part_3c')}
      </p>
      <h3>
        {t('venue.directions.train.sub_title_2')}
      </h3>
      <p>
        {t('venue.directions.train.directions_2_part_1a')}
        <span className="text-highlight green">Nová Ves pod Pleší</span> 
        {t('venue.directions.train.directions_2_part_1b')}                
        <a href="https://maps.app.goo.gl/oS48m15NMRXTYvew7" target="_blank" rel="noreferrer">
          {t('venue.directions.train.directions_2_part_1c')}
        </a>
        {t('venue.directions.train.directions_2_part_1d')}
      </p>
    </div>
  );
}

export default Train;