// libraries
import { useTranslation } from "react-i18next";

// assets
import UberQR from "../../../assets/images/qr/uber-app-download.webp";
import BoltQR from "../../../assets/images/qr/bolt-app-download.png";

// styles
import "./tabcontent.styles.css";

function RideSharing() {
  const { t } = useTranslation();

  return (
    <div className="tabcontent__container">
      <h3>
        {t('venue.directions.ridesharing.sub_title_1')}
      </h3>
      <p>
        {t('venue.directions.ridesharing.directions_1_part_1a')}
        <span className="text-highlight green">Uber</span> & <span className="text-highlight green">Bolt</span>
        {t('venue.directions.ridesharing.directions_1_part_1b')}
      </p>
      <p>
        {t('venue.directions.ridesharing.directions_1_part_2a')}
        <span className="text-highlight yellow">Smíchovské Nádraží</span>, 
        {t('venue.directions.ridesharing.directions_1_part_2b')}
      </p>
      <h3>
        {t('venue.directions.ridesharing.sub_title_2')}
      </h3>
      <p>
        {t('venue.directions.ridesharing.directions_2')}
      </p>
      <div className="ridesharing__flex">
        <div className="ridesharing__flex--item">
          <span className="text-highlight green">Uber</span>
          <img src={UberQR} alt="qr code for Uber app" />
        </div>
        <div className="ridesharing__flex--item">
          <span className="text-highlight green">Bolt</span>
          <img src={BoltQR} alt="qr code for Bolt app" />
        </div>
      </div>
    </div>
  );
}

export default RideSharing;