// libraries
import { Link } from "react-router-dom";

// styles
import "./card.styles.css";

function Card({ linkTitle, title, description }) {
  return (
    <div className="card bx-md">
      <div className="card__title">
        <h3>{title}</h3>
      </div>
      <div className="card__description">
        {description}
      </div>
      <Link
      onClick={() => window.scrollTo(0, 0)}
      to={"/" + linkTitle.toLowerCase()}></Link>
    </div>
  );
}

export default Card;