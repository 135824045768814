// libraries
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// styles
import "./navbar.styles.css";

// assets
import Logo from "../../assets/images/vectors/site-logo.svg";

function Navbar() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const { t } = useTranslation();

  function checkIfScrolling() {
    if (window.scrollY > 0) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false);
    }
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    window.addEventListener('scroll', checkIfScrolling);
    // unsubscribe
    return () => {
      window.removeEventListener('scroll', checkIfScrolling);
    }
  }, []);

  return (
    <nav className={hasScrolled ? 'isScrolling' : ''}>
      <img className="nav__logo" src={Logo} alt="website logo" />
      <div className="nav__links">
        <NavLink
          onClick={scrollToTop}
          to="/home">
            {t("nav.home")}
        </NavLink>
        <NavLink
          onClick={scrollToTop}
          to="/venue">
            {t("nav.venue")}
        </NavLink>
        <NavLink
          onClick={scrollToTop}
          to="/agenda">
            {t("nav.agenda")}
        </NavLink>
        <NavLink
          onClick={scrollToTop}
          to="/accommodation">
            {t("nav.accommodation")}
        </NavLink>
      </div>
    </nav>
  );
}

export default Navbar;

