// libraries
import { useTranslation } from "react-i18next";

// components
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import Translator from "../../components/translator/translator.component";

// assets
import Dress from "../../assets/images/vectors/dress.svg";
import Weather from "../../assets/images/vectors/weather.svg";
import Theme from "../../assets/images/vectors/theme-clothes.svg";
import DocPalette from "../../assets/docs/wedding-palette.pdf";

// styles
import "./agenda.styles.css";

function Agenda() {
  const { t } = useTranslation();

  return (
    <>
      <Header
        title={t('header.agenda.title')}
        highlight={t('header.agenda.titleHighlight')}
        buttonText={t('header.agenda.titleButtonText')}
      />
      <main>
        <article>
          <h2 className="article-heading-l2">
            {t('agenda.intro.title')}
          </h2>
          <table>
            <tbody>
              <tr>
                <td>12:30</td>
                <td>{t('agenda.intro.table.item_1')}</td>
              </tr>
              <tr>
                <td>13:30</td>
                <td>{t('agenda.intro.table.item_2')}</td>
              </tr>
              <tr>
                <td>14:15</td>
                <td>{t('agenda.intro.table.item_3')}</td>
              </tr>
              <tr>
                <td>15:00</td>
                <td>{t('agenda.intro.table.item_4')}</td>
              </tr>
              <tr>
                <td>16:30</td>
                <td>{t('agenda.intro.table.item_5')}</td>
              </tr>
              <tr>
                <td>17:30</td>
                <td>{t('agenda.intro.table.item_6')}</td>
              </tr>
              <tr>
                <td>19:30</td>
                <td>{t('agenda.intro.table.item_7')}</td>
              </tr>
              <tr>
                <td>20:00</td>
                <td>{t('agenda.intro.table.item_8')}</td>
              </tr>
              <tr>
                <td>21:00</td>
                <td>{t('agenda.intro.table.item_9')}</td>
              </tr>
            </tbody>
          </table>
          <h2 className="article-heading-l2">
            {t('agenda.dress_code.title')}
          </h2>
          <div className="article__grid mb-lg">
            <div className="article__grid--icon">
              <img src={Dress} alt="dress icon" />
            </div>
            <div className="article__grid--description">
              <p>
                {t('agenda.dress_code.paragraph_1')}
              </p>
            </div>
            <div className="article__grid--description">
              <p>
                {t('agenda.dress_code.paragraph_2')}
              </p>
            </div>
            <div className="article__grid--icon">
              <img src={Weather} alt="dress icon" />
            </div>
            <div className="article__grid--icon">
              <img src={Theme} alt="dress icon" />
            </div>
            <div className="article__grid--description">
              <p>
                {t('agenda.dress_code.paragraph_3a')}
                <a href={DocPalette} rel="noreferrer" target="_blank">
                  {t('agenda.dress_code.paragraph_3b')}
                </a>
                {t('agenda.dress_code.paragraph_3c')}
              </p>
            </div>
          </div>
        </article>
      </main>
      <Footer />
      <Translator />
    </>
  );
}

export default Agenda;